import {
  Log,
  User,
  UserManager,
  UserSettings,
  WebStorageStateStore,
} from 'oidc-client';
import jwtToken from 'jwt-decode';
import Router from 'next/router';
import { ReactNode, useContext, useEffect, useState } from 'react';
import React from 'react';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import uniqueId from 'lodash/uniqueId';

import { hasRetrys } from '../utilities/hasRetrys';

const SILENT_REFRESH_IFRAME_ID = 'SILENT_REFRESH_IFRAME_ID';
const REFRESH_TOKEN_STORAGE_KEY = 'ledger_refresh_token';

export type UserType = Omit<User, 'profile'> & { profile: CognitoInfo };
interface CognitoInfo {
  currentTenantId: string;
  'custom:tenantList': string;
  'cognito:username': string;
  email: string;
  email_verified: string;
  name: string;
  sub: string;
  role: string;
  staffId: string;
  country: string;
  given_name: string;
  family_name: string;
}

export const authLink = (user: UserType) =>
  setContext(async (req, { headers }) => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    const authorizationToken = refreshToken || user.access_token || null;

    return {
      ...headers,
      headers: {
        authorization: authorizationToken
          ? `Bearer ${authorizationToken}`
          : null,
        'tenant-id': user.profile.currentTenantId,
        retry: hasRetrys(req.operationName),
      },
    };
  });

export const errorLink = (redirectRoute: string) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      console.error(
        `[🌏 Network error]: Operation: ${operation.operationName}, Message: ${networkError}`
      );
    }

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        const { message, extensions } = err;
        console.error(
          `[⚠️ GraphQL error]: Operation: ${
            operation.operationName
          }, Message: ${message}, Code: ${extensions && extensions.code}`
        );

        if (extensions && extensions.code === 'UNAUTHENTICATED') {
          signin(redirectRoute);
        }
      }
    }
  });

const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
let APP_DOMAIN = process.env.REACT_APP_COGNITO_APP_DOMAIN;
let REDIRECT_URI = process.env.REACT_APP_COGNITO_REDIRECT_URI; //fallback
if (typeof window != 'undefined') {
  REDIRECT_URI = window.location.origin; //include protocol and port
  if (window.location.origin.includes('access-aps')) {
    APP_DOMAIN = process.env.REACT_APP_COGNITO_APP_DOMAIN_NEW;
  }
}
const REDIRECT_URI_SILENT = REDIRECT_URI + '/silent-refresh.html';
const REDIRECT_URI_SIGNOUT = REDIRECT_URI;

const userManager =
  typeof window === 'undefined'
    ? ((undefined as any) as UserManager)
    : new UserManager({
        userStore: new WebStorageStateStore({
          prefix: 'contacts_',
          store: localStorage,
        }),
        authority: APP_DOMAIN,
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        silent_redirect_uri: REDIRECT_URI_SILENT,
        automaticSilentRenew: false,
        includeIdTokenInSilentRenew: false,
        accessTokenExpiringNotificationTime: 250,
        loadUserInfo: false,
        response_type: 'token',
        scope: 'openid profile email',
        // post_logout_redirect_uri: REDIRECT_URI_SIGNOUT,
        // stateStore: this.stateStore,
        // revokeAccessTokenOnSignout: true,
        metadata: {
          authorization_endpoint: `https://${APP_DOMAIN}/signin`,
          userinfo_endpoint: `https://${APP_DOMAIN}/oauth2/userInfo`,
          end_session_endpoint: `https://${APP_DOMAIN}/signout?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI_SIGNOUT}`,
        },
      });

export const LOCAL_STORAGE_REDIRECT_URL_KEY = 'redirectURL';

export const signin = (redirectRoute?: string) => {
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

  userManager.signinRedirect().then(() => {
    if (redirectRoute) {
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL_KEY, redirectRoute);
    }
  });
};

export const signout = () => {
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

  Object.entries(localStorage)
    .map((x) => x[0])
    .filter((x) => x.substring(0, 4) === 'oidc')
    .map((x) => localStorage.removeItem(x));

  userManager.signoutRedirect();
};

const getUserInfo = async () => {
  const userInfo = await userManager.getUser();
  if (userInfo) return (userInfo as any) as UserType;
};

const storeCredentials = async (hash: string) => {
  const parsedHash = new URLSearchParams(
    hash.substr(1) // skip the first char (#)
  );

  const profileInfo: any = jwtToken(parsedHash.get('id_token') as string);

  profileInfo['currentTenantId'] = parsedHash.get('tenant') as string;

  profileInfo['staffId'] = parsedHash.get('staff') as string;
  profileInfo['role'] = parsedHash.get('role') as string;
  profileInfo['country'] = parsedHash.get('country') as string;
  profileInfo['name'] = profileInfo['name']
    ? (profileInfo['name'] as string)
    : `${profileInfo['given_name']} ${profileInfo['family_name']}`;

  const hashExpiry =
    parsedHash.has('expires_in') &&
    parseInt(parsedHash.get('expires_in') as string);

  const expires_at = hashExpiry
    ? Math.floor(Date.now() / 1000) + hashExpiry
    : profileInfo['exp'];

  Log.info(
    'token refresh at - ',
    new Date((expires_at - 250) * 1000).toTimeString()
  );

  Log.info('token expires at - ', new Date(expires_at * 1000).toTimeString());

  const userSettings: UserSettings = {
    id_token: parsedHash.get('id_token') as string,
    access_token: parsedHash.get('access_token') as string,
    token_type: parsedHash.get('token_type') as string,
    state: parsedHash.get('state') as string,
    expires_at,
    profile: profileInfo,
  } as UserSettings;

  userManager.settings.extraQueryParams = {};
  userManager.settings.extraQueryParams.access_token = parsedHash.get(
    'access_token'
  );

  const authenticatedUser = (new User(userSettings) as any) as UserType;

  // @ts-ignore
  await userManager.storeUser(authenticatedUser);
  await userManager.getUser();

  return authenticatedUser;
};

async function silentRefresh() {
  return new Promise(async (resolve, reject) => {
    const nodeListContainsRefreshIframe = document.querySelectorAll(
      `iframe[id*=${SILENT_REFRESH_IFRAME_ID}]`
    );

    // prevents the refresh from triggering multiple times
    if (nodeListContainsRefreshIframe.length) {
      return;
    }

    const onMessage = (messageEvent: MessageEvent) => {
      // we got a message on the window. If it checks out, store the creds, teardown, return true
      if (!messageEvent) {
        return;
      }
      if (messageEvent.origin !== REDIRECT_URI) {
        return;
      }

      if (
        !messageEvent.data ||
        !(typeof messageEvent.data === 'string') ||
        !messageEvent.data.startsWith('#')
      ) {
        return;
      }
      const urlHash = messageEvent.data;
      // wait for the data to be tucked away, then teardown with success
      storeCredentials(urlHash).then(() => teardown(true));
    };

    const onTimeout = () => {
      // we didn't get a message in time. Teardown with an error
      teardown('Cognito (newAuth.ts) timeout waiting for message from iframe');
    };

    // we either got a response, or we have an error, either way, clean up after ourselves and resolve/reject
    const teardown = (result: boolean | string) => {
      window.removeEventListener('message', onMessage);
      window.clearTimeout(timer);
      window.document.body.removeChild(frame);

      if (typeof result === 'boolean') {
        resolve(result);
      } else if (typeof result === 'string') {
        reject(new Error(result));
      }
    };

    const user = await userManager.getUser();
    if (!user) {
      // this is bad, we need that dang access token to get the next one
      reject(
        new Error(
          'Cognito (newAuth.ts) Failed to retrieve user when doing silent redirect'
        )
      );
      return;
    }

    // make up the silent refresh url. Be nice to use some of the auth library to do this - there's a createSigninRequest
    // but heckin there's some parameters go into that
    // instead, this is based off a semi legit silent redirect url that the library made up once
    const silentRefreshUrl =
      `https://${APP_DOMAIN}/signin` +
      `?client_id=${CLIENT_ID}` +
      `&redirect_uri=${encodeURIComponent(REDIRECT_URI_SILENT || '')}` +
      `&response_type=token` +
      `&scope=openid%20profile%20email` +
      `&prompt=none` +
      `&access_token=${user.access_token}`;

    // make up the iframe
    const frame = window.document.createElement('iframe');
    frame.setAttribute('id', uniqueId(SILENT_REFRESH_IFRAME_ID));
    frame.style.visibility = 'hidden';
    frame.style.position = 'absolute';
    frame.style.display = 'none';
    frame.style.width = '0';
    frame.style.height = '0';
    window.document.body.appendChild(frame);

    // listen for the message
    window.addEventListener('message', onMessage, false);

    // setup a timer for the timeout
    const timer = window.setTimeout(onTimeout, 10000);

    // kick off the loading
    frame.src = silentRefreshUrl;
  });
}

type AuthStates =
  | {
      type: 'fetching' | 'unauthenticated';
      user?: UserType;
    }
  | {
      type: 'authenticated';
      user: UserType;
    };

let AuthContext = React.createContext<{ type: 'none' } | AuthStates>({
  type: 'none',
});

export function AuthProvider({ children }: { children: ReactNode }) {
  let [user, setUser] = useState<AuthStates>({ type: 'fetching' });

  useEffect(() => {
    if (window.location.hash) {
      storeCredentials(window.location.hash).then((user) => {
        setUser({ type: 'authenticated', user });
        Router.replace('/');
      });
    } else {
      getUserInfo().then((user) => {
        setUser(
          user ? { type: 'authenticated', user } : { type: 'unauthenticated' }
        );
      });
    }
    setAccessExpiring();
  }, []);

  const setAccessExpiring = async () => {
    userManager.events.addAccessTokenExpiring(async () => {
      Log.warn('token refreshing...');
      await silentRefresh();
      const user = (await userManager.getUser()) as UserType | null;
      const token = user?.access_token || '';

      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);

      user && setUser({ type: 'authenticated', user });
    });
  };

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  let auth = useContext(AuthContext);
  if (auth.type === 'none') {
    throw new Error('Please wrap your app in a AuthProvider');
  }
  return auth;
}

export function useUser() {
  let auth = useAuth();
  if (auth.type !== 'authenticated') {
    throw new Error('useUser can only be used when the user is authenticated');
  }
  return auth.user;
}
